import { UserManager } from "oidc-client";
import { withPrefix } from "gatsby";
import jwtDecode from "jwt-decode";

function AuthService() {
  const settings = {
    authority: process.env.AUTH_ISSUER,
    response_type: "code",
    client_id: process.env.AUTH_CLIENT_ID,
    scope: "openid profile email adviser",
    loadUserInfo: true,

    filterProtocolClaims: true,
    redirect_uri: `${window.location.origin}${withPrefix(
      "/implicit/callback"
    )}`,
    post_logout_redirect_uri: `${window.location.origin}${withPrefix(
      "/implicit/signout"
    )}`,

    automaticSilentRenew: true
  };

  const userManager = new UserManager(settings);

  return {
    getUser: async function() {
      const user = await userManager.getUser();
      if (user) {
        const {
          adviser_companyName: companyName,
          adviser_companyPostcode: companyPostcode,
          adviser_fcaNumber: fcaNumber
        } = jwtDecode(user.access_token);
        return {
          ...user,
          adviser: {
            companyName,
            companyPostcode,
            fcaNumber
          }
        };
      }

      return user;
    },

    login: async function() {
      return await userManager.signinRedirect();
    },

    logout: async function() {
      await userManager.signoutRedirect();
    },

    signinRedirect: async function() {
      return await userManager.signinRedirectCallback();
    },

    signoutRedirect: async function() {
      await userManager.signoutRedirectCallback();
    }
  };
}

export default function createAuthService() {
  return new AuthService();
}
